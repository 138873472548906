$theme-colors: (
    "primary": rgb(107 33 168),
    "danger": red,
    "light": #fff,
    "success": #198754
);

.page {
    height: 100vh;
    width: 100vw;
    background-image: url("../public/background.png");
    background-repeat: repeat-y;
}

@import "~bootstrap/scss/bootstrap";
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;600&display=swap');

body {
    font-family: 'Montserrat', sans-serif;
}

